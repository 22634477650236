import {
  Collection,
  CollectionDeleteResult,
  CollectionSearchResult,
  CollectionSpace,
  CollectionValues,
} from '../types'
import { generatePath } from '../utils'

import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  SPACES: '/collection/spaces',
  PORTFOLIOS: '/collection',
  PORTFOLIO_BY_ID: '/collection/:collectionId',
  MOVE_IDS: '/collection/moveIds',
  DELETE_MULTIPLE: '/collection/delete',
  COLLECTION_SEARCH: '/collection/search',
}

export type FetchRequest = {
  collectionId?: string
  space?: CollectionSpace
}

export type SearchRequest = {
  fullText: string
  targetPortfolioId?: string
}

export class PortfolioApi {
  static async create({
    parentId,
    space,
    values,
  }: {
    values: CollectionValues
    parentId?: string
    space?: CollectionSpace
  }): Promise<Collection> {
    const response = await agent.post(PATHS.PORTFOLIOS, {
      collectionData: { ...values },
      space,
      targetCollectionId: parentId,
    })

    //@ts-ignore
    return response.data.item
  }

  static async update(collectionId: string, update: any): Promise<Collection> {
    const response = await agent.patch(generatePath(PATHS.PORTFOLIO_BY_ID, { collectionId }), {
      updateDescription: update,
    })

    //@ts-ignore
    return response.data.item
  }

  static async fetchSpaces(): Promise<Collection[]> {
    const response = await agent.get(PATHS.SPACES)

    //@ts-ignore
    return response.data.items
  }

  static async fetchWithEntries({
    collectionId,
    space = 'user',
  }: FetchRequest): Promise<Collection> {
    const response = await agent.get(generatePath(PATHS.PORTFOLIO_BY_ID, { collectionId }), {
      params: {
        depth: 2,
      },
    })

    //@ts-ignore
    return response.data.items[0]
  }

  static async delete(collectionId: string): Promise<CollectionDeleteResult> {
    //@ts-ignore
    return await agent.delete(generatePath(PATHS.PORTFOLIO_BY_ID, { collectionId }))
  }

  static async move(
    fromParentId: string,
    targetParentId: string,
    entryIds: string[]
  ): Promise<any> {
    const response = await agent.patch(generatePath(PATHS.MOVE_IDS), {
      allowReplace: false,
      entryIds: entryIds.join(','),
      fromParentId,
      isCopy: false,
      targetParentId,
    })

    return response
  }

  static async deleteMultiple(collectionIds: string[]): Promise<any> {
    const response = await agent.patch(PATHS.DELETE_MULTIPLE, {
      collectionIds,
    })

    return response
  }

  static search(request: SearchRequest): Promise<CollectionSearchResult> {
    //@ts-ignore
    return agent.get(PATHS.COLLECTION_SEARCH, {
      params: request,
    })
  }
}
