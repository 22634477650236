import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { IconButton, ThemeProvider, Tooltip, useTheme } from '@mui/material'
import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocalState } from '../../hooks/useLocalState'
import { THEME_DARK, THEME_LIGHT } from '../../theme'
import { BuildInfo } from '../AppVersion/types'

type ColorMode = 'light' | 'dark'

interface CiboUiContextValue {
  buildInfo: BuildInfo
  colorMode: {
    toggleColorMode(): void
    currentMode: ColorMode
  }
  events: EventTarget
}

export const CiboUiContext = createContext<CiboUiContextValue>({
  buildInfo: {
    releaseName: '',
    gitHash: '',
    buildDate: '',
  },
  colorMode: {
    toggleColorMode: () => {},
    currentMode: 'light',
  },
  events: new EventTarget(),
})

const darkBg = THEME_DARK.palette.background.default
const lightBg = THEME_LIGHT.palette.background.default

const darkCss = `:root { color-scheme: dark; }
.mapboxgl-popup-content {
  pointer-events: none;
  background: ${darkBg};
}
.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip { border-top-color: ${darkBg};}
.mapboxgl-popup-anchor-bottom-left > .mapboxgl-popup-tip { border-top-color: ${darkBg};}
.mapboxgl-popup-anchor-bottom-right > .mapboxgl-popup-tip { border-top-color: ${darkBg};}
.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip { border-bottom-color: ${darkBg};}
.mapboxgl-popup-anchor-top-right > .mapboxgl-popup-tip { border-bottom-color: ${darkBg};}
.mapboxgl-popup-anchor-top-left > .mapboxgl-popup-tip { border-bottom-color: ${darkBg};}
.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip { border-right-color: ${darkBg};}
.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip { border-left-color: ${darkBg};}
`

const lightCss = `:root { color-scheme: light; }
.mapboxgl-popup-content {
  pointer-events: none;
  background: ${lightBg};
}
.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip { border-top-color: ${lightBg};}
.mapboxgl-popup-anchor-bottom-left > .mapboxgl-popup-tip { border-top-color: ${lightBg};}
.mapboxgl-popup-anchor-bottom-right > .mapboxgl-popup-tip { border-top-color: ${lightBg};}
.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip { border-bottom-color: ${lightBg};}
.mapboxgl-popup-anchor-top-left > .mapboxgl-popup-tip { border-bottom-color: ${lightBg};}
.mapboxgl-popup-anchor-top-right > .mapboxgl-popup-tip { border-bottom-color: ${lightBg};}
.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip { border-right-color: ${lightBg};}
.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip { border-left-color: ${lightBg};}
`

export const CiboUiToggle = () => {
  const { t } = useTranslation('@cibo/ui/components/ColorModeToggle')
  const theme = useTheme()
  const { colorMode } = useContext(CiboUiContext)
  return (
    <Tooltip
      placement="bottom"
      title={`${t(theme.palette.mode === 'dark' ? 'darkMode' : 'lightMode')}`}
    >
      <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  )
}

export const CiboUiProvider = ({
  buildInfo,
  children,
}: PropsWithChildren<{ buildInfo: BuildInfo }>) => {
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [mode, setMode] = useLocalState<ColorMode>(prefersDarkMode ? 'dark' : 'light', 'colorMode')
  const toggleColorMode = useCallback(
    // @ts-ignore color me surprised that this is expecting the prevMode to be callable.
    () => setMode((prevMode: ColorMode) => (prevMode === 'light' ? 'dark' : 'light')),
    []
  )

  const [events] = useState(new EventTarget())

  return (
    <>
      <style>{mode === 'dark' ? darkCss : lightCss}</style>
      <CiboUiContext.Provider
        value={{
          buildInfo,
          colorMode: { toggleColorMode, currentMode: mode as ColorMode },
          events,
        }}
      >
        <ThemeProvider theme={mode === 'dark' ? THEME_DARK : THEME_LIGHT}>{children}</ThemeProvider>
      </CiboUiContext.Provider>
    </>
  )
}
