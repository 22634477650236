import { AxiosRequestConfig } from 'axios'
import { ascend, path, sort } from 'ramda'
import {
  CollectionSpace,
  EnrollmentWindow,
  FDRStatusQuery,
  MpxListResponse,
  PaginationRequest,
  ProgramBenchmarkName,
  ProgramConfig,
  ProgramConfigSponsorOrg,
  ProgramContentListItem,
  ProgramStatus,
  ResourceDetail,
  RewardOpportunity,
  RewardToken,
  SortModel,
  TraitId,
  UserId,
} from '../types'
import { FieldFDRStats } from '../types/FieldQueryTypes'
import { MpxResponseBase } from '../types/MpxTypes'
import { getMpAgent } from './utils'

const mpAgent = getMpAgent()

const PATHS = {
  PREQUALIFY: '/qualify/v1',

  ALL_PROGRAMS: '/programs',
  COMMIT: '/programs/:programId/commit',
  ELIGIBLE: '/programs/:programId/eligible',
  OPPORTUNITIES: '/programs/:programId/opportunities',
  PROGRAM: `/programs/:programId`,
  PROGRAM_GROWERS: `/programs/:programId/groupBy/grower`,
  SELECT: '/programs/:programId/select',
  STATS: '/programs/stats',
  TEST_RULES: '/programs/eligible',
  USER_PROGRESS: '/programs/userProgress',
}

export type SelectResourcesRequestItem = {
  resourceId: string
  details?: ResourceDetail[]
}

export type SelectResourcesRequest = {
  programId: string
  resources: SelectResourcesRequestItem[]
  desiredPhaseTransition?: ProgramBenchmarkName
}

export type CommitResourcesRequest = {
  programId: string
  resourceIds: string[]
  dryRun?: boolean
  allOrNothing?: boolean
  deleteWfOnEmpty?: boolean
}
export interface CommitResourcesResponse extends MpxResponseBase {
  committed: string[]
  ineligible: string[]
}

export interface ResourceEligibility {
  resourceId: string
  reason: Array<{ traitId: TraitId; reason: string }>
}
export interface EligibiligyResponse extends MpxResponseBase {
  incomplete: Array<ResourceEligibility>
  ineligible: Array<ResourceEligibility>
  eligible: Array<string>
}

export interface GrowerProgramRequest {
  programId: string
  organization?: boolean
  //userId
  owner?: UserId
  orgId?: string

  filter?: {
    enrollmentStatus?: EnrollmentWindow['enrollmentStatus']
    enrollmentStartGte?: EnrollmentWindow['enrollmentStart']
    enrollmentEndLt?: EnrollmentWindow['enrollmentEnd']
    isEnrolling?: EnrollmentWindow['isEnrolling']
    openDateGte?: EnrollmentWindow['openDate']
    openDateLt?: EnrollmentWindow['openDate']
    sponsorIds?: Array<ProgramConfigSponsorOrg['accountId']>
  }

  sort?: SortModel<
    | 'closeDate'
    | 'enrollmentEnd'
    | 'enrollmentStart'
    | 'enrollmentStatus'
    | 'isEnrolling'
    | 'openDate'
    | 'sponsor'
  >
}

export interface ProgramStatsRequest {
  userId?: UserId
  orgId?: string
  fdrStats?: Array<FDRStatusQuery>
}

export interface ResourceCount {
  fields: number
  acres: number
}

export type PerOrgLimit = Record<
  number,
  {
    committed: { acres: number; fields: number }
    max: { acres?: number; fields?: number }
  }
>

export interface ProgramStat {
  committed: ResourceCount
  eligible: ResourceCount
  programId: string
  parentProgramId?: string
  isTier: boolean
  globalLimit: {
    committed: ResourceCount
    max: {
      acres?: number
      growers?: number
      perGrower?: number
      fields?: number
    }
  }
  fdrStats?: Array<FieldFDRStats>
  perOrgLimit: PerOrgLimit
}
export interface ProgramStatsResponse extends MpxResponseBase {
  earnings: Array<{
    currency: RewardToken
    amount: number
  }>

  firstEnrollmentDate: string
  enrolledPrograms: number
  enrolledProgramsList?: string[]
  enrolledFields: number
  enrolledAcres: number
  programs: Array<ProgramStat>
}

export interface TestRulesResponse extends MpxResponseBase {
  programStatuses: ProgramStatus[]
}

/**
 * Note that this API DOES NOT conform to the pagination standard - specifically
 * sorting is perfomed via the `groupBy` param and `sort` is not supported.  Hence
 * the awkward interface extension below
 */
export interface ProgramOpportunitiesRequest
  extends Pick<PaginationRequest<{}>, 'limit' | 'offset'> {
  groupBy?: 'user' | 'org'
  direction?: 'asc' | 'desc'
  userId?: UserId
  orgId?: string
  programId: string
}

export type ProgramGrowersSortFields =
  | 'grower'
  | 'eligible'
  | 'ineligible'
  | 'enrolled'
  | 'draft'
  | 'prequalified'
export interface ProgramGrowersQuery extends PaginationRequest<ProgramGrowersSortFields> {
  programId: string
  space: CollectionSpace
}
export class GrowerProgramsAPI {
  static userProgress({ userId }: { userId: UserId }) {
    return mpAgent
      .get<{ userProgress: any }>(PATHS.USER_PROGRESS, { params: { userId } })
      .then(response => response && response.data?.userProgress)
  }

  static select({ programId, ...payload }: SelectResourcesRequest) {
    return mpAgent
      .post<EligibiligyResponse>({ template: PATHS.SELECT, params: { programId } }, payload)
      .then(response => response && response.data)
  }

  static async allPrograms(params?: { organization?: boolean }, config?: AxiosRequestConfig) {
    return mpAgent
      .get<MpxListResponse<ProgramContentListItem>>(
        `${PATHS.ALL_PROGRAMS}${!params?.organization ? '' : '?organization=true'}`,
        config
      )
      .then(response => {
        if (!response) {
          return []
        }

        return sort(
          // @ts-ignore ramda typing newly causing problems here
          ascend(path(['content', 'fields', 'priority'])),
          response.data.items
        )
      })
  }

  static program(
    { programId, ...params }: Omit<GrowerProgramRequest, 'filter' | 'sort'>,
    options?: any
  ) {
    return mpAgent
      .get<MpxListResponse<ProgramConfig>>(
        { template: PATHS.PROGRAM, params: { programId } },
        {
          params,
          ...options,
        }
      )
      .then(response => response?.data.items[0])
  }

  static leave(programId: string, resourceIds: string[]) {
    return mpAgent.delete(
      { template: PATHS.PROGRAM, params: { programId } },
      {
        data: { resourceIds },
      }
    )
  }

  static commit({ programId, ...params }: CommitResourcesRequest) {
    return mpAgent
      .post<CommitResourcesResponse>(
        { template: PATHS.COMMIT, params: { programId } },
        {
          allOrNothing: true,
          ...params,
        }
      )
      .then(response => response?.data)
  }

  static opportunities({
    programId,
    direction = 'desc',
    groupBy = 'user',
    ...rest
  }: ProgramOpportunitiesRequest) {
    return mpAgent
      .get<MpxListResponse<RewardOpportunity>>(
        {
          template: PATHS.OPPORTUNITIES,
          params: {
            programId,
            groupBy: `${groupBy}:${direction}`,
          },
        },
        { params: { ...rest } }
      )
      .then(r => r.data)
  }

  static stats(request: ProgramStatsRequest) {
    return mpAgent
      .get<ProgramStatsResponse>(PATHS.STATS, {
        params: {
          ...request,
          fdrStats: request.fdrStats ? JSON.stringify(request.fdrStats) : undefined,
        },
      })
      .then(response => response?.data)
  }
  static testRules(details: ResourceDetail[]) {
    return mpAgent
      .post<TestRulesResponse>(PATHS.TEST_RULES, { details })
      .then(response => response?.data)
  }
}
