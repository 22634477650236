import { THEME_DARK } from '@cibo/ui'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  InputLabel,
  Link,
  Paper,
  Stack,
  ThemeProvider,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { PropsWithChildren, useEffect } from 'react'

import { useEnvironmentAuthCheck } from '../../hooks'

const ENV_AUTH_COOKIE = 'CbEnvExpressAccessToken'

type EnvAuthCookieForm = {
  envAuthCookie?: string
}

export const EnvAuthCheck = ({ children }: PropsWithChildren<{}>) => {
  const needsEnvAuth = useEnvironmentAuthCheck()

  /**
   * If there is an existing ENV_AUTH_COOKIE we need to:
   *
   * 1. clear the existing cookie by setting the expiration to now
   * 2. jiggle the cookie handle by reloading the page (this makes little sense to me)
   */
  useEffect(() => {
    if (needsEnvAuth.data && document.cookie.includes(ENV_AUTH_COOKIE)) {
      const expires = new Date().toUTCString()
      // clear the ENV_AUTH_COOKIE, but preserve all others
      document.cookie.split(';').forEach(function (c) {
        if (c.includes(ENV_AUTH_COOKIE)) {
          document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${expires};path=/`)
        } else {
          document.cookie = c
        }
      })
      window.location.reload()
    }
  }, [needsEnvAuth.data])

  if (import.meta.env.PROD) {
    return <>{children}</>
  }

  if (needsEnvAuth.isPending) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  const handleUpdateCookie = ({ envAuthCookie }: EnvAuthCookieForm) => {
    const cookieValues = document.cookie.split('; ')
    const index = cookieValues.findIndex(val => val.startsWith(ENV_AUTH_COOKIE))
    const newValue = `${ENV_AUTH_COOKIE}=${envAuthCookie?.replace(/\s/g, '')};path=/`
    cookieValues.splice(index, 1, newValue)
    const newCookie = index >= 0 ? cookieValues.join('; ') : `${newValue};`
    document.cookie = newCookie
    window.location.reload()
  }

  return needsEnvAuth.data ? (
    document.cookie.includes(ENV_AUTH_COOKIE) ? null : (
      <Formik
        initialValues={{
          envAuthCookie: '',
        }}
        onSubmit={handleUpdateCookie}
      >
        <Form>
          <ThemeProvider theme={THEME_DARK}>
            <Paper>
              <Grid
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: '100vh' }}
                container
              >
                <Grid xs={12} md={5} item>
                  <Card elevation={5}>
                    <CardHeader title="Environment Auth Needed" />
                    <CardContent>
                      <Stack spacing={3}>
                        <span>
                          Please copy your Express Access Token from{' '}
                          <Link
                            target="_blank"
                            href="https://admin.mp.dev.cibo.tech/content/html/authInfo.ftl"
                          >
                            the backend documentation
                          </Link>{' '}
                          into the box below
                        </span>

                        <Stack>
                          <InputLabel shrink htmlFor="envAuthCookie">
                            Express Access Token
                          </InputLabel>
                          <Field
                            id="envAuthCookie"
                            name="envAuthCookie"
                            component={TextField}
                            InputProps={{
                              inputProps: { autoComplete: 'off' },
                            }}
                          />
                        </Stack>
                      </Stack>
                    </CardContent>
                    <CardActions>
                      <Button type="submit" variant="contained">
                        Update
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </ThemeProvider>
        </Form>
      </Formik>
    )
  ) : (
    <>{children}</>
  )
}
