import {
  Box,
  Grid,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import numeral from 'numeral'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormFactor } from '../../../hooks/useWidth'
import { SEARCH_FILTERS } from './SearchFilterConfig'

import { MpxFieldHistoryTypes, MpxSearchQuery, MpxSearchTagTypes } from '@cibo/core'
import { mpxSearchInitialState } from './mpxSearchInitialState'

const ValueLabelComponent = (props: {
  children: ReactElement
  value: number
  format?(value: number): string | number
}) => {
  const { children, value, format = value => value } = props

  return (
    <Tooltip placement="bottom" enterTouchDelay={0} title={format(value)}>
      {children}
    </Tooltip>
  )
}

// Use initial search conditions and take out bounds
export const initialValues: any = {
  ...mpxSearchInitialState.query,
  lowerLeft: undefined,
  upperRight: undefined,
}

interface Props {
  query: MpxSearchQuery
  onDismiss?(event: MpxSearchQuery): void
  onSubmit?(event: MpxSearchQuery): void
  onUpdate(event: MpxSearchQuery): void
}

export enum FILTER_TYPES {
  ACREAGE = 'acreage',
  HISTORY = 'fldHistory',
  NCCPI = 'nccpi',
  PRODUCTIVITY = 'productivity',
  REGEN_POTENTIAL = 'regenPotentialPerAcre',
  STABILITY = 'stability',
  STATE_PRODUCTIVITY = 'statePi',
  SUSTAINABILITY = 'sustainability',
  TILLABLE = 'tillablePercent',
  TYPE = 'tags',
}

const TONS = (value: number) => `${numeral(value).format('0.01')}t/ac`

export const FilterSection = ({ onUpdate, query }: Props) => {
  const { isMobile } = useFormFactor()
  const theme = useTheme()
  const { t } = useTranslation('searchFilters')

  const handleChange = (name: string) => (value: (number | undefined)[] | number) => {
    if (!Array.isArray(value)) {
      onUpdate({
        ...query,
        [`${name}`]: value === null ? undefined : value,
      })
    } else {
      onUpdate({
        ...query,
        [`${name}Ge`]: value[0] === null ? undefined : value[0],
        [`${name}Lt`]: value[1] === null ? undefined : value[1],
      })
    }
  }

  const handleToggleButton = (type: 'tags' | 'fldHistory') => (event: any, value: string) => {
    const thisQuery: any = query[type]
    if (thisQuery?.find((item: string) => item === value)) {
      const filteredParams = (query[type] as string[]).filter(param => param !== value)
      if (filteredParams.length === 0) {
        filteredParams.push(SEARCH_FILTERS.ANY)
      }
      onUpdate({
        ...query,
        [type]: filteredParams,
      })
    } else {
      const specificValues = thisQuery?.filter((param: any) => param !== SEARCH_FILTERS.ANY)
      onUpdate({
        ...query,
        [type]: value === SEARCH_FILTERS.ANY ? [value] : [...specificValues, value],
      })
    }
  }

  // TODO Double check this: the initial value is overriden by query which has the merged intitial query with query params from the reducer.
  const params = {
    ...query,
  }

  const handleRangeChange =
    (field: string) => (event: Event, value: number | Array<number>, activeThumb: number) => {
      handleChange(field)(value)
    }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="caption">{t('regenPotential')}</Typography>
        <Box px={4}>
          <Slider
            value={
              [
                params.regenPotentialPerAcreLt || 0,
                params.regenPotentialPerAcreGe || 1.5,
              ] as number[]
            }
            min={0}
            max={1.5}
            step={0.01}
            onChange={handleRangeChange(FILTER_TYPES.REGEN_POTENTIAL)}
            marks={[
              { value: 0, label: TONS(0) },
              { value: 1.5, label: TONS(1.5) },
            ]}
            valueLabelDisplay="auto"
            components={{ ValueLabel: props => <ValueLabelComponent {...props} format={TONS} /> }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="caption">{t('nccpi')}</Typography>
        <Box px={4}>
          <Slider
            value={[params.nccpiLt || 0, params.nccpiGe || 100] as number[]}
            min={0}
            max={100}
            step={isMobile ? 10 : 1}
            onChange={handleRangeChange(FILTER_TYPES.NCCPI)}
            marks={[
              { value: 0, label: 0 },
              { value: 100, label: 100 },
            ]}
            valueLabelDisplay="auto"
            components={{ ValueLabel: props => <ValueLabelComponent {...props} /> }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Typography variant="caption">{t('landType')}</Typography>
          <ToggleButtonGroup>
            {SEARCH_FILTERS.PROPERTY_TYPES.map(({ value, name, Icon, color }) => {
              const isSelected = params.tags
                ? params.tags.includes(value as MpxSearchTagTypes)
                : false
              return Icon ? (
                <ToggleButton
                  value={value}
                  key={name}
                  sx={{ height: 60, width: 60 }}
                  selected={isSelected}
                  onClick={handleToggleButton('tags')}
                >
                  <Stack alignItems="center">
                    <Icon color={isSelected ? color : (theme.palette.grey[500] as string)} />
                    <Typography variant="caption">{t(name)}</Typography>
                  </Stack>
                </ToggleButton>
              ) : null
            })}
          </ToggleButtonGroup>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Typography variant="caption">{t('historicalUse')}</Typography>
          <ToggleButtonGroup>
            {SEARCH_FILTERS.FIELD_HISTORY.map(({ value, name, Icon, color }) => {
              const isSelected = params.fldHistory
                ? params.fldHistory.includes(value as MpxFieldHistoryTypes)
                : false
              return Icon ? (
                <ToggleButton
                  value={value}
                  key={name}
                  sx={{ height: 60, width: 60 }}
                  selected={isSelected}
                  onClick={handleToggleButton('fldHistory')}
                >
                  <Stack alignItems="center">
                    <Icon color={isSelected ? color : (theme.palette.grey[500] as string)} />
                    <Typography variant="caption">{t(name)}</Typography>
                  </Stack>
                </ToggleButton>
              ) : null
            })}
          </ToggleButtonGroup>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default FilterSection
