import { getMpAgent } from './utils'
import { stringify } from 'query-string'

const agent = getMpAgent()

type StabMapQuery = any
type StabMapResult = any

const PATHS = {
  FETCH_STAB_MAP: '/insights/stabmap',
}

export class StabMapApi {
  //@ts-ignore
  static fetch(query: StabMapQuery, options?: any): Promise<StabMapResult> {
    return agent.get(`${PATHS.FETCH_STAB_MAP}?${stringify(query)}`, options)
  }
}
