import { getMpAgent } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'

export const useEnvironmentAuthCheck = () =>
  useQuery({
    queryFn: async () => {
      try {
        await getMpAgent().get('/auth/check')
        return false
      } catch (error: any) {
        if (error.response?.status === 401) {
          return true
        }
        throw error
      }
    },
    queryKey: ['environmentAuthCheck'],
    enabled:
      window.location.host.includes('localhost') ||
      window.location.host.endsWith('.localdomain') ||
      window.location.host.endsWith('.local'),
    retry: false,
  })
