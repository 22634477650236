// @ts-nocheck
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { UserProperties, setupAnalytics, useAuth } from '@cibo/profile'

import { AnalyticsEvent, EVENTS } from 'utils/analytics'
import { initFirebase } from 'utils/initFirebase'
import { reportUserProperties } from 'utils/reportUserProperties'

let analytics

//@ts-ignore
const isIntegrationTest = window.Cypress || window.localStorage.driver === 'playwright'

const trackEvent = (event: AnalyticsEvent, passedParams?: any) => {
  if (!event?.name || !analytics) return
  logEvent(analytics, event.name, passedParams)
}

export const Analytics = () => {
  useEffect(() => {
    const firebase = initFirebase()
    analytics = firebase && getAnalytics(firebase)
  }, [])
  const { userId, authResolved, orgId } = useAuth()
  const { pathname } = useLocation()
  const initialRoute = pathname
  const [sentInitialPageView, setSentInitialPageView] = useState(false)

  const trackPageView = (path: string, passedId?: string) => {
    trackEvent(EVENTS.PAGE_VIEW, {
      path,
      userId: passedId || 'unset',
    })
  }

  // setup tracking platforms, register initial pageview for on unmount if not otherwise registered
  useEffect(() => {
    if (isIntegrationTest || window['location'].hostname === 'localhost') return

    // this sets the value of a pre-existing function in the `@cibo/profile` package to the functions
    // established in `@cibo/impact` so that they can be called in lower level packages.
    setupAnalytics({
      trackEvent,
      setUserId: (id?: any) => {
        if (!!analytics) {
          setUserId(analytics, `${id}`)
        }
      },
      setUserProperties: (props: Omit<UserProperties, 'environment'>) => {
        if (!analytics) return
        setUserProperties(analytics, props)
      },
    })

    return () => {
      if (!sentInitialPageView) {
        trackPageView(initialRoute, userId)
        setSentInitialPageView(true)
      }
    }
  }, [])

  // set user properties once user is logged in
  useEffect(() => {
    if (userId) {
      reportUserProperties({
        userId,
        orgId,
      })
    } else {
      reportUserProperties()
    }
  }, [userId])

  // register initial pageview and initialize global navigation event firing
  useEffect(() => {
    if (authResolved) {
      if (!sentInitialPageView) {
        trackPageView(initialRoute, userId)
        setSentInitialPageView(true)
      } else {
        trackPageView(pathname, userId)
      }
    }
  }, [pathname, authResolved])

  return null
}
