import { AxiosResponse } from 'axios'
import {
  BoundaryRequest,
  Inventory,
  InventoryBoundary,
  InventoryListItem,
  InventoryReportResponse,
  InventoryStatus,
  InventoryTarget,
  InventoryTargetResponse,
  MpxListResponse,
  MpxResponseBaseSingle,
  SetBoundaryOfftakesRequest,
} from '../types'
import { generatePath } from '../utils'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  INVENTORY: '/inventory',
  INVENTORY_DETAIL: '/inventory/:inventoryId',
  INVENTORY_CSV: '/inventory/:inventoryId/reportCsv',
  INVENTORY_STATUS: '/inventory/:inventoryId/status',
  INVENTORY_BASE_PERIOD: '/inventory/:inventoryId/basePeriod',
  INVENTORY_TARGET: '/inventory/:inventoryId/target',
  INVENTORY_BOUNDARY: '/inventory/:inventoryId/boundary',
  INVENTORY_BOUNDARY_DETAIL: '/inventory/:inventoryId/boundary/:boundaryId',
  INVENTORY_BOUNDARY_OFFTAKE: '/inventory/:inventoryId/boundary/:boundaryId/historicalOfftakeData',
  INVENTORY_BOUNDARY_UPLOAD: '/inventory/:inventoryId/boundary-upload',
}

const appendFipsToInventoryResponse = ({
  data: {
    item: { boundaries, ...restItem },
    ...restData
  },
  ...response
}: AxiosResponse<MpxResponseBaseSingle<Inventory>>) => {
  const newBoundaries = boundaries.map(({ areaDef, ...restBoundary }) => {
    const newAreas = areaDef.map(({ fips, ...restArea }) => {
      const newFips =
        restArea.type === 'fips' ? (fips?.includes('fips:') ? fips : `fips:${fips}`) : undefined
      return {
        ...restArea,
        fips: newFips,
      }
    })
    return {
      ...restBoundary,
      areaDef: newAreas,
    }
  })
  return {
    ...response,
    data: {
      ...restData,
      item: {
        ...restItem,
        boundaries: newBoundaries,
      },
    },
  } as AxiosResponse<MpxResponseBaseSingle<Inventory>>
}

const appendFipsToBoundaryResponse = ({
  data: {
    item: { areaDef, ...restItem },
    ...restData
  },
  ...restResponse
}: AxiosResponse<MpxResponseBaseSingle<InventoryBoundary>>) => {
  const newBoundaries = areaDef.map(({ fips, ...restArea }) => {
    const newFips =
      restArea.type === 'fips' ? (fips?.includes('fips:') ? fips : `fips:${fips}`) : undefined
    return {
      ...restArea,
      fips: newFips,
    }
  })
  return {
    ...restResponse,
    data: {
      ...restData,
      item: {
        ...restItem,
        areaDef: newBoundaries,
      },
    },
  } as AxiosResponse<MpxResponseBaseSingle<InventoryBoundary>>
}

const removeFipsFromBoundaryRequest = ({ areaDef, ...restData }: BoundaryRequest) => {
  const newAreaDef = !!areaDef
    ? areaDef.map(({ fips, ...restArea }) => {
        const newFips =
          restArea.type === 'fips'
            ? fips?.includes('fips:')
              ? fips.split(':')[1]
              : fips
            : undefined
        return {
          ...restArea,
          fips: newFips,
        }
      })
    : undefined
  return {
    ...restData,
    areaDef: newAreaDef,
  } as BoundaryRequest
}

export class InventoryApi {
  static createInventory(inventoryName: string) {
    return agent.post<MpxResponseBaseSingle<Inventory>>(PATHS.INVENTORY, {
      name: inventoryName,
    })
  }

  static getInventories() {
    return agent.get<MpxListResponse<InventoryListItem>>(PATHS.INVENTORY)
  }

  static getInventory(inventoryId: string, options?: any) {
    return agent
      .get<MpxResponseBaseSingle<Inventory>>(
        generatePath(PATHS.INVENTORY_DETAIL, { inventoryId }),
        options
      )
      .then(appendFipsToInventoryResponse)
  }

  static deleteInventory(inventoryId: string) {
    return agent.delete<MpxResponseBaseSingle<Inventory>>(
      generatePath(PATHS.INVENTORY_DETAIL, { inventoryId })
    )
  }

  static getInventoryReport(inventoryId: string) {
    return agent.get<InventoryReportResponse>(generatePath(PATHS.INVENTORY_CSV, { inventoryId }))
  }

  static setInventoryStatus(inventoryId: string, data: { status: InventoryStatus }) {
    return agent.put<null>(generatePath(PATHS.INVENTORY_STATUS, { inventoryId }), data)
  }

  static setInventoryBasePeriod(inventoryId: string, data: { years: number[] }) {
    return agent.put<null>(generatePath(PATHS.INVENTORY_BASE_PERIOD, { inventoryId }), data)
  }

  static setInventoryTargets(inventoryId: string, data: InventoryTarget) {
    return agent.put<MpxResponseBaseSingle<InventoryTargetResponse>>(
      generatePath(PATHS.INVENTORY_TARGET, { inventoryId }),
      data
    )
  }

  static getInventoryTargets(inventoryId: string) {
    return agent.get<MpxResponseBaseSingle<InventoryTargetResponse>>(
      generatePath(PATHS.INVENTORY_TARGET, { inventoryId })
    )
  }

  static uploadBoundary(inventoryId: string, data: FormData) {
    return agent.post<MpxResponseBaseSingle<InventoryBoundary>>(
      generatePath(PATHS.INVENTORY_BOUNDARY_UPLOAD, { inventoryId }),
      data
    )
  }

  static getBoundary(inventoryId: string, boundaryId: string) {
    return agent
      .get<MpxResponseBaseSingle<InventoryBoundary>>(
        generatePath(PATHS.INVENTORY_BOUNDARY_DETAIL, { inventoryId, boundaryId })
      )
      .then(appendFipsToBoundaryResponse)
  }

  static createBoundary(inventoryId: string, data: BoundaryRequest) {
    return agent
      .post<MpxResponseBaseSingle<InventoryBoundary>>(
        generatePath(PATHS.INVENTORY_BOUNDARY, { inventoryId }),
        removeFipsFromBoundaryRequest(data)
      )
      .then(appendFipsToBoundaryResponse)
  }

  static updateBoundary(inventoryId: string, boundaryId: string, data: BoundaryRequest) {
    return agent
      .patch<MpxResponseBaseSingle<InventoryBoundary>>(
        generatePath(PATHS.INVENTORY_BOUNDARY_DETAIL, { inventoryId, boundaryId }),
        removeFipsFromBoundaryRequest(data)
      )
      .then(appendFipsToBoundaryResponse)
  }

  static setBoundaryOfftakes({
    inventoryId,
    boundaryId,
    cropOfftakes,
  }: SetBoundaryOfftakesRequest) {
    return agent
      .put<MpxResponseBaseSingle<InventoryBoundary>>(
        generatePath(PATHS.INVENTORY_BOUNDARY_OFFTAKE, { inventoryId, boundaryId }),
        {
          cropOfftakes,
        }
      )
      .then(appendFipsToBoundaryResponse)
  }

  static deleteBoundary(args: { inventoryId: string; boundaryId: string }) {
    return agent.delete<MpxResponseBaseSingle<InventoryBoundary>>(
      generatePath(PATHS.INVENTORY_BOUNDARY_DETAIL, args)
    )
  }
}
