import { GeneralResourceDetail, MpxItemResponse, ResourceDetailsUpdate } from '../types'
import { generatePath } from '../utils'
import { DetailsResponse } from './types'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  DETAIL: '/user-data-details/:resourceId',
}

export class UserDetailsAPI {
  static get<T extends GeneralResourceDetail = GeneralResourceDetail>({
    resourceId,
  }: {
    resourceId: string
  }) {
    return agent
      .get<DetailsResponse<T>>(generatePath(PATHS.DETAIL, { resourceId }))
      .then(response => response?.data)
  }

  static update({ resourceId, details }: ResourceDetailsUpdate) {
    return agent
      .patch<MpxItemResponse<GeneralResourceDetail>>(generatePath(PATHS.DETAIL, { resourceId }), {
        details,
      })
      .then(response => response?.data)
  }

  //delete by nulling out the result block
  static delete({ resourceId, details }: ResourceDetailsUpdate) {
    return agent
      .patch<MpxItemResponse<GeneralResourceDetail>>(generatePath(PATHS.DETAIL, { resourceId }), {
        params: {
          details: details.map(({ traitId, year }) => ({ traitId, year })),
        },
      })
      .then(response => response?.data)
  }
}
