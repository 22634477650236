import { AuthUserPermission, UnimplementedPermissions } from '@cibo/core'
import { flatten } from 'ramda'
import { useSelector } from 'react-redux'
import { selectAuthUserPermissions } from '../store/AuthReducer/Session'

import { useCallback, useMemo } from 'react'
import { PermissionCheckArgs, checkPermissions } from '../utils'

export const usePermissions = () => {
  const permissions = useSelector(selectAuthUserPermissions)

  const can = useCallback(
    ({ useAny, ...rest }: Omit<PermissionCheckArgs, 'permissions'>) => {
      let useAnyWithDebug = useAny
      if (
        useAny &&
        flatten([useAny]).some(permission => UnimplementedPermissions.includes(permission))
      ) {
        const useAnyFlat = flatten([useAny])
        useAnyWithDebug = [...useAnyFlat, AuthUserPermission.DEBUG_USER] as AuthUserPermission[]
      }
      return checkPermissions({ permissions, useAny: useAnyWithDebug, ...rest })
    },
    [checkPermissions, permissions.join()]
  )

  const isDebugUser = useMemo(
    () => checkPermissions({ permissions, useAny: [AuthUserPermission.DEBUG_USER] }),
    [checkPermissions, permissions.join()]
  )

  return {
    isDebugUser,
    permissions,
    can,
  }
}
