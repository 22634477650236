import { RemoveShareRequest, ShareRequest } from '../types'
import { getMpAgent } from './utils'
import { generatePath } from '../utils'

const agent = getMpAgent()

export const PATHS = {
  USERS: '/share/user/list',
  SHARE: '/share',
  RESOURCE_SHARES: '/share/:resourceId',
}

export class ShareApi {
  static listUsers() {
    // @ts-ignore
    return agent.get(PATHS.USERS).then(response => response?.data?.items)
  }

  static share({
    access,
    emails,
    message,
    resourceIds,
    sendEmail = true,
  }: ShareRequest): Promise<any> {
    return (
      agent
        .put(PATHS.SHARE, {
          access,
          emailMsg: message,
          emails,
          ids: resourceIds,
          sendEmail,
        })
        // @ts-ignore
        .then(response => response?.data)
    )
  }

  static forResource(resourceId: string): Promise<any> {
    return (
      agent
        .get(generatePath(PATHS.RESOURCE_SHARES, { resourceId }))
        // @ts-ignore
        .then(response => {
          // @ts-ignore
          const items = response?.data?.items
          const item = items && items[0]

          return item?.shareData?.raList
        })
    )
  }

  static removeShare({ resourceId, emails }: RemoveShareRequest): Promise<any> {
    return agent.delete(generatePath(PATHS.RESOURCE_SHARES, { resourceId }), { data: { emails } })
  }
}
