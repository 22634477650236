import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import deepmerge from 'deepmerge'
import { generatePath } from '../../utils'

export type ErrorListener = (error: AxiosError) => void

type PathConfig = { template: string; params: Record<string, string> }

const finalPath = (path: string | PathConfig) => {
  switch (typeof path) {
    case 'string':
      return path
    default:
      return generatePath(path.template, path.params)
  }
}

const defaultConfig: AxiosRequestConfig = {
  /**
   * @todo figure out a more appropriate timeout value
   */
  timeout: 60 * 1000,
  headers: {
    'Cache-Control': 'no-cache',
  },
}

export type AgentMiddleware = (config: AxiosRequestConfig) => AxiosRequestConfig

export type AgentOptions = {
  agentConfig?: AxiosRequestConfig
  requestMiddlewares?: AgentMiddleware[]
  onGetResponse?(response: AxiosResponse): void
}

const getReleaseName = () => {
  try {
    // @ts-ignore vite puts that there
    return import.meta.env.VITE_RELEASE_NAME
  } catch (e) {
    // @ts-ignore vite puts that there
    return process.env.RELEASE_NAME || 'unknown'
  }
}

const getRequestConfig = () => {
  return {
    headers: {
      CbRequestPath: `release=${getReleaseName()}; timestamp=${Date.now().toString()}`,
    },
  }
}

export const getBaseAgent = (options: AgentOptions = {}) => {
  const agentConfig = options.agentConfig || {}
  const requestMiddlewares = options.requestMiddlewares || []

  const onGetResponse = options.onGetResponse

  const requestConfig = (config?: AxiosRequestConfig) =>
    requestMiddlewares.reduce(
      (latestConfig, middlewareStep) => middlewareStep(latestConfig),
      deepmerge.all([defaultConfig, agentConfig, getRequestConfig(), config || {}])
    )

  // Attach helpful request specifics to the error
  const handleError = (
    error: AxiosError & { pathTemplate?: string; pathParams?: Record<string, string> },
    path: string | PathConfig
  ) => {
    if (typeof path === 'string') {
      error.pathTemplate = path
    } else {
      error.pathTemplate = path.template
      error.pathParams = path.params
    }

    throw error
  }

  return {
    get: <T>(path: string | PathConfig, config?: AxiosRequestConfig) =>
      axios
        .get<T>(finalPath(path), requestConfig(config))
        .then((response: AxiosResponse) => {
          if (onGetResponse) {
            onGetResponse(response)
          }
          return response as AxiosResponse<T>
        })
        .catch(error => handleError(error, path)),
    put: <T>(path: string | PathConfig, data: any, config?: AxiosRequestConfig) =>
      axios
        .put<T>(finalPath(path), data, requestConfig(config))
        .catch(error => handleError(error, path)),
    patch: <T>(path: string | PathConfig, data: any, config?: AxiosRequestConfig) =>
      axios
        .patch<T>(finalPath(path), data, requestConfig(config))
        .catch(error => handleError(error, path)),

    post: <T>(path: string | PathConfig, data: any = {}, config?: AxiosRequestConfig) =>
      axios
        .post<T>(finalPath(path), data, requestConfig(config))
        .catch(error => handleError(error, path)),

    delete: <T>(path: string | PathConfig, config?: AxiosRequestConfig) =>
      axios
        .delete<T>(finalPath(path), requestConfig(config))
        .catch(error => handleError(error, path)),
  }
}
