type RequestQueueItem = {
  request: Function
  resolve(response: any): void
  reject(error: Error): void
}

const requestQueue: RequestQueueItem[] = []

const processNextRequest = () => {
  const item = requestQueue.shift()
  if (!item) return

  item
    .request()
    .then(item.resolve)
    .catch(item.reject)
    .finally(processNextRequest)
}

/* Force these async functions to run sequentially. This is intended
 * to avoid repeated requests that potentially involve the same locks,
 * which can result in timeouts.
 *
 * example: mutating details (locks db by user)
 */
export const queueRequest = <T>(request: Function) =>
  new Promise<T>((resolve, reject) => {
    requestQueue.push({ request, resolve, reject })

    processNextRequest()
  })
