import { AxiosResponse } from 'axios'
import { AuthConfigResult, UserId } from '../types'
import {
  AuthChangePasswordResult,
  AuthCheckResult,
  AuthCreateFormTokenResult,
  AuthDirectLoginResult,
  AuthDirectLoginWithPasswordQuery,
  AuthDirectLoginWithVerificationCodeQuery,
  AuthDirectRequestVerificationCodeQuery,
  AuthDirectRequestVerificationCodeResult,
  AuthRegisterVisitorQuery,
  AuthRegisterVisitorResult,
  UserInfoResult,
} from '../types/AuthTypes'
import {
  RegisterVerifedUserCreateQuery,
  RegisterVerifedUserSendCodeQuery,
  RegisterVerifedUserSetLoginQuery,
  RegisterVerifiedUserCreateResult,
  RegisterVerifiedUserSendCodeResult,
  RegisterVerifiedUserSetLoginResult,
} from '../types/AuthTypes/RegisterVerifiedUser'

import { getMpAgent } from './utils'

let ipGeoLocation: string

const onGetResponse = (response: AxiosResponse) => {
  const newLocation = response.headers['mpipgeolocation']
  if (newLocation) {
    ipGeoLocation = newLocation
  }
}

const agent = getMpAgent({
  onGetResponse,
})

export const getIpGeoLocation = () => ipGeoLocation

const PATHS = {
  // This is NOT the public user.
  // This is the "environment" auth0 user that has access to our dev environment.
  HEALTH_CHECK: '/health/info',
  DEV_AUTH_CHECK: '/auth/check',
  CONFIG: '/auth/config',

  // These pertain to the public user.
  AUTH_CHECK: '/auth/self/info',
  FETCH_USER_DATA: '/user/mpdata/query',
  CREATE_FORM_TOKEN: '/auth/form/createToken',
  REGISTER_VISITOR: '/auth/user/registerVisitor',
  DIRECT_LOGIN_PASSWORD: '/auth/login/byPassword',
  DIRECT_LOGIN_CODE: '/auth/login/byCode',
  DIRECT_LOGIN_REQUEST_VERIFICATION_CODE: '/auth/user/sendVerify',
  DIRECT_LOGOUT: '/auth/logout',

  REGISTER_VERIFIED_SEND_CODE: '/auth/newContact/sendVerify',
  REGISTER_VERIFIED_CREATE: '/auth/user/createInitial',
  REGISTER_VERIFIED_SET_LOGIN: '/auth/user/setLoginData',

  UPDATE_CREDENTIALS: '/user/self/setData',
  USER_INFO: '/auth/self/info',

  EMULATE_USER: '/emulate/user',
}

export class AuthAPI {
  static environmentAccessCheck(config = {}) {
    /**
     * @todo use more appropriate path
     */
    return AuthAPI.healthCheck(config)
  }

  static healthCheck(config = {}) {
    return agent.get(PATHS.HEALTH_CHECK, config)
  }

  static authCheck(config = {}) {
    return agent.get<AuthCheckResult>(PATHS.AUTH_CHECK, config)
  }
  static userInfo(config = {}) {
    return agent.get<UserInfoResult>(PATHS.USER_INFO, config)
  }

  static devAuthCheck(config = {}) {
    return agent.get(PATHS.DEV_AUTH_CHECK, config)
  }

  static createFormToken() {
    return agent.get<AuthCreateFormTokenResult>(`${PATHS.CREATE_FORM_TOKEN}`)
  }

  static registerVisitor(query: AuthRegisterVisitorQuery) {
    return agent.put<AuthRegisterVisitorResult>(`${PATHS.REGISTER_VISITOR}`, { ...query })
  }

  static directLoginWithPassword(query: AuthDirectLoginWithPasswordQuery) {
    return agent.post<AuthDirectLoginResult>(`${PATHS.DIRECT_LOGIN_PASSWORD}`, { ...query })
  }

  static directLoginWithVerificationCode(query: AuthDirectLoginWithVerificationCodeQuery) {
    return agent.post<AuthDirectLoginResult>(`${PATHS.DIRECT_LOGIN_CODE}`, { ...query })
  }

  static directLogout() {
    return agent.post<AuthDirectLoginResult>(`${PATHS.DIRECT_LOGOUT}`, {})
  }

  static registerVerifedUserSendCode(query: RegisterVerifedUserSendCodeQuery) {
    return agent.post<RegisterVerifiedUserSendCodeResult>(`${PATHS.REGISTER_VERIFIED_SEND_CODE}`, {
      ...query,
    })
  }

  static registerVerifedUserCreate(query: RegisterVerifedUserCreateQuery) {
    return agent.put<RegisterVerifiedUserCreateResult>(`${PATHS.REGISTER_VERIFIED_CREATE}`, {
      ...query,
    })
  }

  static registerVerifedUserUpdateLogin(query: RegisterVerifedUserSetLoginQuery) {
    return agent.put<RegisterVerifiedUserSetLoginResult>(`${PATHS.REGISTER_VERIFIED_SET_LOGIN}`, {
      ...query,
    })
  }

  static directLoginRequestVerificationCode(query: AuthDirectRequestVerificationCodeQuery) {
    return agent.post<AuthDirectRequestVerificationCodeResult>(
      PATHS.DIRECT_LOGIN_REQUEST_VERIFICATION_CODE,
      query
    )
  }

  static changePassword(oldPassword: string, newPassword: string) {
    return agent.put<AuthChangePasswordResult>(PATHS.UPDATE_CREDENTIALS, {
      currentPassword: oldPassword,
      password: newPassword,
    })
  }

  static emulateUser(username?: string, userId?: UserId) {
    const param = username ? { username: username } : { userId: userId }
    return agent.post<AuthDirectLoginResult>(PATHS.EMULATE_USER, param)
  }

  static config() {
    return agent.get<AuthConfigResult>(PATHS.CONFIG)
  }
}
