import ImageNotSupported from '@mui/icons-material/ImageNotSupported'
import { Box } from '@mui/material'
import { memo, useState } from 'react'
import { Theme } from '../../consts'
import { Config } from '../../types/Config'
import { layersForProfileSkin } from './layersForProfileSkin'
import { pathSegmentForLayer } from './pathSegmentForLayer'
import { LandThumbnailProps, LandThumbnailSkin } from './types'

const pixelRatio = window.devicePixelRatio

const LandThumbnailBase = ({
  landProfile,
  width,
  height,
  skin = LandThumbnailSkin.SELECTED,
  style = undefined,
  mapThumbnailUrl,
}: LandThumbnailProps) => {
  const [unresolvedImage, setUnresolvedImage] = useState<boolean>(false)
  if ([width, height].includes(Number.NaN)) return null
  const imageStyle = {
    height,
    width,
    minHeight: height,
    maxWidth: width,
    ...style,
    backgroundColor: Theme.color.surface.contrast.primary,
  }
  if (unresolvedImage || (!landProfile?.geometrySlug && !landProfile?.tillableGeometrySlug)) {
    return (
      <Box
        sx={{
          ...(imageStyle as any),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageNotSupported
          sx={{ color: 'background.paper' }}
          height={height * 0.85}
          width={width * 0.85}
        />
      </Box>
    )
  }

  const layers = layersForProfileSkin(landProfile, skin)

  const sourceComponents = [
    `v2/${Math.round(width * pixelRatio)}x${Math.round(height * pixelRatio)}`,
    ...layers.map(pathSegmentForLayer),
  ]

  const baseUrl = mapThumbnailUrl || Config.get('MAP_THUMBNAIL_URL')

  const sourceUri = `${baseUrl}${sourceComponents.join('/')}.jpg`

  return (
    <img
      alt={landProfile.name}
      style={{ borderRadius: '4px', overflow: 'hidden', ...imageStyle }}
      src={sourceUri}
      loading="lazy"
      onError={() => setUnresolvedImage(true)}
    />
  )
}

export const LandThumbnail = memo(LandThumbnailBase)
