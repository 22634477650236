import { Array2d } from '@cibo/core'

export const BASE_SATELLITE_TILE =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
export const BASE_SATELLITE_TILE_MAX_ZOOM = 16

export const WORLD_IMAGERY_SOURCE_OPTIONS = {
  type: 'raster',
  tiles: [BASE_SATELLITE_TILE],
  tileSize: 256,
}
export const FLOODPLAIN_OPTIONS = {
  type: 'vector',
  tiles: ['mapbox://cibo-apps.floodplains'],
}

export const MAINLAND_US_BOUNDS: Array2d = [
  [-124.749, 24.5018],
  [-66.9406, 49.3845],
]

export const US_CENTER = [-95.890216, 40.163]

export const MAINLAND_US_BOUNDS_PADDED: Array2d = [
  [-173.23, 20.22],
  [-23.11, 57.8],
]

export const MIN_MAP_PADDING = 20
