import { MpxResponseError } from '@cibo/core'
import { logRequestError } from '@cibo/ui'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import {
  MpxUserDataAPI,
  MpxUserDataRequest,
  USER_DATA_KEYS,
  UserDataKey,
} from '../api/MpxUserDataAPI'
import { useAuth } from './useAuth'

export { USER_DATA_KEYS } from '../api/MpxUserDataAPI'

export const USER_DATA_QUERY_KEY = 'user_data'

export const useUserData = <T>(key?: UserDataKey) => {
  const { isLoggedIn } = useAuth()
  const queryClient = useQueryClient()
  const queryKey = [USER_DATA_QUERY_KEY, key]

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey })
  }, [isLoggedIn])

  const userData = useQuery<T, Error, T>({
    queryKey,
    queryFn: async () => (key ? MpxUserDataAPI.fetch().then(response => response[key] || {}) : {}),

    placeholderData: keepPreviousData,
    enabled: !!key && !!isLoggedIn,
  })

  const [updateError, setUpdateError] = useState<Error | undefined>()

  return {
    ...userData,
    fetchError: userData.error,
    update: (value: T) => {
      if (!key) {
        // prevent trying to update with no key
        return Promise.reject()
      }

      if (!isLoggedIn) {
        setUpdateError(new Error('not logged in'))
      }

      return MpxUserDataAPI.update({ key, value })
        .then(response => {
          setUpdateError(undefined)
          queryClient.invalidateQueries({ queryKey })
        })
        .catch((error: Error) => {
          setUpdateError(error)
          console.log('error saving user data', error)
          logRequestError(error as AxiosError)
          throw error
        })
    },
    updateError,
  }
}

export const useAllUserData = () =>
  useQuery({
    queryKey: [USER_DATA_QUERY_KEY],
    queryFn: () => MpxUserDataAPI.fetch(),
  })

export const useUpdateUserData = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ key, value }: MpxUserDataRequest) => MpxUserDataAPI.update({ key, value }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [USER_DATA_QUERY_KEY] }),
  })
}

/**
 *
 * @returns first login timestamp cached forever
 */
export const useFirstVisit = () =>
  // @ts-ignore
  useQuery<string | undefined, MpxResponseError, string>({
    queryKey: [USER_DATA_QUERY_KEY, 'first_visit'],
    queryFn: async () => {
      const response = await MpxUserDataAPI.fetch()
      if (!response[USER_DATA_KEYS.FIRST_LOGIN]) {
        try {
          const now = new Date()
          await MpxUserDataAPI.update({ key: USER_DATA_KEYS.FIRST_LOGIN, value: now.toString() })
        } catch (err) {
          logRequestError(err as AxiosError)
        }
      }

      return response
    },
    select: (data: Record<string, string | undefined>) =>
      data[USER_DATA_KEYS.FIRST_LOGIN] as string | undefined,
    retry: 0,
    gcTime: Infinity,
  })
