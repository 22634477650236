import {
  AUTH0_CONFIG,
  BUILD_CONFIG,
  CONTENTFUL_CONFIG,
  DEV_CONFIG,
  FIREBASE_CONFIG,
  MP_CONFIG,
  NODE_SERVICE_CONFIG,
  SENTRY_CONFIG,
  //@ts-ignore
  getConfigWithKeys,
} from '@cibo/core'
import { MAPBOX_CONFIG } from '@cibo/mapgl'
import { CIBO_UI_CONFIG } from '@cibo/ui'

export type ENTERPRISE_CONFIG =
  | 'BASE_FRONT_END_URL_NATIVE'
  | 'MP_BASE_URL_FULL'
  | 'CIBO_EXTERNAL_URL'
  | 'EXPORT_URL'
  | 'FOOTPRINT_URL'
  | 'MAP_THUMBNAIL_URL'
  | 'MAPTILES_SERVICE_URL'
  | 'FIELD_EDITOR_HELP_VIDEO_ID'
  | 'BUY_CREDITS_LANDING_PAGE'
  | 'ENROLL_LAND_LANDING_PAGE'
  | 'homepage'

export type APP_CONFIG =
  | FIREBASE_CONFIG
  | MP_CONFIG
  | AUTH0_CONFIG
  | BUILD_CONFIG
  | CONTENTFUL_CONFIG
  | DEV_CONFIG
  | ENTERPRISE_CONFIG
  | MAPBOX_CONFIG
  | SENTRY_CONFIG
  | NODE_SERVICE_CONFIG
  | CIBO_UI_CONFIG
export const Config = getConfigWithKeys<APP_CONFIG>()
