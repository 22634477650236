import {
  AuthCheck,
  AuthFlashMessages,
  AuthLayout,
  AuthUserPermission,
  Can,
  FailedToLoadContent,
  Loading,
  selectOrg,
  useAuth,
  useOrganizationContent,
  useWindowTitle,
} from '@cibo/profile'
import * as Sentry from '@sentry/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { hideLauncherCss as hideCiboZendeskWidget } from 'components/ZendeskWidget'
import { useSetAppMeta } from 'hooks'
import { TranslationPreviewer } from 'i18n/TranslationPreviewer'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Analytics } from 'pages/Analytics'
import { Suspense, lazy, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Config } from 'types/Config'
import { useOrgDialect } from './i18n'

const AuthenticatedArea = lazy(() => import('./pages/AuthenticatedArea'))
const UnauthenticatedArea = lazy(() => import('./pages/UnauthenticatedArea'))

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools').then(d => ({
    default: d.ReactQueryDevtools,
  }))
)

export const ImpactApp = () => {
  useWindowTitle()
  const { authResolved, isLoggedIn, userId } = useAuth()

  const navigate = useNavigate()
  const [deepLink, setDeepLink] = useState<string>()

  const [showDevtools, setShowDevtools] = useState(false)

  useEffect(() => {
    // @ts-ignore toggleDevTools isn't natively included in window, but exists here
    window.toggleDevtools = () => setShowDevtools(old => !old)
  }, [])

  useEffect(() => {
    Sentry.setUser(isLoggedIn ? { id: userId?.toString() } : null)
  }, [isLoggedIn, userId])

  useEffect(() => {
    if (authResolved && isLoggedIn && deepLink) {
      setDeepLink(undefined)
      navigate(deepLink, { replace: true })
    }
  }, [authResolved, deepLink, isLoggedIn])
  const homepageIsSet = !!Config.get('homepage')

  useOrgDialect()
  const orgContent = useOrganizationContent()
  useSetAppMeta()

  const organization = useSelector(selectOrg)
  const [orgContentProblem, setOrgContentProblem] = useState(false)
  useEffect(() => {
    if (isLoggedIn && (!organization?.contentfulOrgId || orgContent.isError)) {
      setOrgContentProblem(true)
      if (!organization?.contentfulOrgId) {
        Sentry.captureException(new Error('user has no org content id'))
      }
      if (orgContent.isError) {
        Sentry.captureException(new Error('error fetching org content'))
      }
    } else {
      setOrgContentProblem(false)
    }
  }, [isLoggedIn, organization, orgContent.isError])

  const appLoading = !authResolved || (organization?.contentfulOrgId && orgContent.isFetching)

  return (
    <>
      <style>{hideCiboZendeskWidget}</style>
      {homepageIsSet && <Analytics />}
      <AuthCheck />
      <AuthFlashMessages />

      {orgContentProblem ? (
        <AuthLayout variant="content">
          <FailedToLoadContent />
        </AuthLayout>
      ) : appLoading ? (
        <Loading large />
      ) : !isLoggedIn ? (
        <Suspense fallback={<Loading large />}>
          <UnauthenticatedArea />
        </Suspense>
      ) : (
        <Suspense fallback={<Loading large />}>
          <AuthenticatedArea />
        </Suspense>
      )}

      <Can useAny={AuthUserPermission.TRANSLATIONS_VIEW}>
        <TranslationPreviewer />
      </Can>
      <Can useAny={AuthUserPermission.DEBUG_USER}>
        <ReactQueryDevtools buttonPosition="bottom-left" />
      </Can>
      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </>
  )
}
