import { getMpAgent } from './utils'

const agent = getMpAgent()

type SoilMapQuery = any
type SoilMapResult = any

const PATHS = {
  FETCH_MAP: '/mpx/soil/allLayers',
  FETCH_BY_RESOURCE_ID: '/insights/soilAllLayers',
}

export class SoilMapApi {
  //@ts-ignore
  static fetch(query: SoilMapQuery): Promise<SoilMapResult> {
    return agent.post(`${PATHS.FETCH_MAP}`, { ...query })
  }
  //@ts-ignore
  static fetchById(resourceId: string, options?: any): Promise<SoilMapResult> {
    return agent.get(`${PATHS.FETCH_BY_RESOURCE_ID}?resourceId=${resourceId}`, options)
  }
}
