import { FDRStatus, FieldFDRStats, OrgUserModel, OrgUserSearchResult } from '@cibo/core'
import { LineLimitTypography, Markdown, intuitiveFromNow, useFigures } from '@cibo/ui'
import { Link, Stack, Typography, styled } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface UseOrgUserFdrStatsColumnProps {
  status: FDRStatus
}

const CellMarkdown = styled(Markdown)(({ theme }) => ({
  '&.MuiTypography-root': {
    marginBottom: 0,
  },
}))

export const useOrgUserFdrStatsColumn = ({ status }: UseOrgUserFdrStatsColumnProps) => {
  const { t } = useTranslation('@cibo/profile/PaginatedUsersTable')
  const { acres, fields } = useFigures()

  return useMemo(
    () =>
      ({
        field: status,
        headerName: t('status', { context: status }),
        minWidth: 80,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }: GridValueGetterParams<OrgUserSearchResult, string>) =>
          row.fdrStats?.find(fdrStat => fdrStat.status === status),
        renderCell: ({
          row,
          value,
        }: GridRenderCellParams<OrgUserSearchResult, FieldFDRStats | undefined>) =>
          !!value ? (
            <Stack>
              <Typography variant="body2">{value.acres ? acres(value.acres) : null}</Typography>
              <Typography variant="caption">
                {value.fields ? fields(value.fields) : null}
              </Typography>
            </Stack>
          ) : null,
      } as GridColDef),
    []
  )
}
export interface UseOrgUserColumnsProps {
  onClickOrganization?: (params: { organizationId?: string }) => void
}

export const useOrgUserColumns = ({ onClickOrganization }: UseOrgUserColumnsProps) => {
  const { t } = useTranslation('@cibo/profile/PaginatedUsersTable')
  const { acres } = useFigures()

  return useMemo(
    () => ({
      grower: {
        field: 'grower',
        headerName: t('persona_grower'),
        minWidth: 200,
        flex: 1,
        filterable: false,
        sortable: false,
        valueGetter: ({ row }: GridValueGetterParams<OrgUserSearchResult, string>) => {
          return new OrgUserModel(row).searchResultsDisplayNameMarkdown
        },
        renderCell: ({ value }: GridRenderCellParams<any, string>) => (
          <CellMarkdown>{value}</CellMarkdown>
        ),
      },
      network: {
        field: 'org',
        headerName: t('growerNetwork'),
        minWidth: 150,
        flex: 1,
        filterable: false,
        valueGetter: ({ row }: GridValueGetterParams<OrgUserSearchResult, string>) =>
          row.org?.label ?? '-',
      },
      name: {
        field: 'familyName',
        headerName: t('name'),
        minWidth: 200,
        flex: 1,
        filterable: false,
        valueGetter: ({ row }: GridValueGetterParams<OrgUserSearchResult, string>) => {
          if (!row.givenName && !row.familyName) {
            return ''
          }
          return `${row.givenName} ${row.familyName}`
        },
        renderCell: ({ row, value }: GridRenderCellParams<OrgUserSearchResult, string>) => {
          return (
            <Stack overflow="hidden">
              <LineLimitTypography lineLimit={1} variant="body2">
                {!!value ? value : '--'}
              </LineLimitTypography>
              {!!row.persona && (
                <LineLimitTypography lineLimit={1} variant="caption">
                  {t('persona', { context: row.persona, ns: '@cibo/profile/PersonaCards' })}
                </LineLimitTypography>
              )}
            </Stack>
          )
        },
      },
      email: {
        field: 'email',
        headerName: t('email'),
        minWidth: 250,
        flex: 1,
        filterable: false,
      },
      organization: {
        field: 'org',
        headerName: t('organization'),
        minWidth: 200,
        flex: 1,
        filterable: false,
        valueGetter: ({ value }: GridValueGetterParams<any, OrgUserSearchResult['org']>) =>
          value?.label,
        renderCell: (params: GridRenderCellParams<any, string, OrgUserSearchResult>) =>
          onClickOrganization && params.row.org?.id ? (
            <Link
              color="secondary"
              onClick={(event: any) => {
                event.stopPropagation()
                onClickOrganization({ organizationId: params.row.org?.id })
              }}
              underline="hover"
            >
              {params.value}
            </Link>
          ) : (
            params.value
          ),
      },
      totalFields: {
        field: 'totalFields',
        headerName: t('fields'),
        filterable: false,
        renderCell: (params: GridRenderCellParams<any, OrgUserSearchResult>) => (
          <Stack>
            <Typography variant="body2">
              {params.row.resources?.acres ? acres(params.row.resources?.acres) : null}
            </Typography>
            <Typography variant="caption">{params.row.resources?.fields}</Typography>
          </Stack>
        ),
      },
      enrolledFieldsRatio: {
        field: 'committedFieldsRatio',
        sortable: false,
        filterable: false,
        headerName: t('participation'),
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<any, OrgUserSearchResult>) => {
          if (!params.row.resources?.fields) return null
          return (
            <Stack>
              <Typography variant="body2">
                {acres(params.row.resources?.committed?.acres)} /{' '}
                {acres(params.row.resources?.acres)}
              </Typography>
              <Typography variant="caption">
                {params.row.resources?.committed?.fields} / {params.row.resources?.fields}
              </Typography>
            </Stack>
          )
        },
      },
      lastLoginDate: {
        field: 'lastLoginDate',
        sortable: false,
        filterable: false,
        headerName: t('lastLoginDate'),
        valueFormatter: (params: GridValueFormatterParams) =>
          !!params.value ? intuitiveFromNow(params.value) : t('never'),
      },
    }),
    [t]
  )
}
