import * as Sentry from '@sentry/react'
import firebaseConfig from 'consts/firebaseConfig.json'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { Config } from 'types/Config'

export const initFirebase = () => {
  const firebaseConfigAppDomain = Config.get('FIREBASE_APP_DOMAIN')
  // @ts-ignore json is not typed
  const config = firebaseConfig[firebaseConfigAppDomain] as FirebaseOptions
  if (config) {
    try {
      return initializeApp(config)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}
