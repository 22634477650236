import { getMpAgent } from './utils'

const mpAgent = getMpAgent()

const UPLOAD_PATH = '/collection/scratch/upload'

export class UploadLandAPI {
  static upload(formData: FormData, uploadAsOneField?: 'true' | 'false') {
    const path = `${UPLOAD_PATH}?assignNewIds=true&oneFieldPerFile=${uploadAsOneField}`
    return mpAgent
      .put(path, formData, { timeout: 150 * 1000 })
      .then((response: any) => response?.data)
  }
}
