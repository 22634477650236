import { getMpNodeAgent } from './utils'

const mpNodeAgent = getMpNodeAgent()

const PATHS = {
  LAND_USAGE: '/land_usage/v1',
}

export type LandUseRequest = {
  bbox: [number, number, number, number]
  year?: string | number
}

export interface LandUseResponseItem {
  color: string
  usage?: string
  pixelCount: number
}

export class LandUseAPI {
  static forRect(params: LandUseRequest) {
    return mpNodeAgent
      .get<LandUseResponseItem[]>(PATHS.LAND_USAGE, {
        params: { ...params, bbox: params.bbox.join(',') },
      })
      .then(response => (response ? response.data : []))
  }
}
