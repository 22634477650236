// @ts-nocheck
import HelpIcon from '@mui/icons-material/Help'
import { ButtonProps, IconButton, styled } from '@mui/material'
import { Children, PropsWithChildren, cloneElement, useEffect } from 'react'

const NotificationButton = styled(IconButton)(({ theme }) => ({
  '&.active': {
    background: theme.palette.secondary.main,
  },
  '&:hover': {
    background: theme.palette.secondary.dark,
  },
}))

export const displayLauncherCss = `
    #launcher {
        display: block;
    }
`
export const hideLauncherCss = `
    #launcher {
        display: none;
    }
`

export const ZendeskWidget = ({
  children,
  position = { horizontal: 'right', vertical: 'top' },
  onClick,
}: PropsWithChildren<
  {
    position?: { horizontal: 'right' | 'left'; vertical: 'top' | 'bottom' }
  } & Pick<ButtonProps, 'onClick'>
>) => {
  useEffect(() => {
    try {
      window.zE &&
        zE('webWidget', 'updateSettings', {
          webWidget: {
            position,
          },
        })
    } catch (e) {
      console.log(e)
    }
  }, [])

  // if zendesk isn't available or is hidden (user banned, etc.)
  const showButton = window.zE && zE('webWidget:get', 'display') !== 'hidden'

  return (
    <>
      <style>{hideLauncherCss}</style>
      {showButton &&
        (!!children ? (
          Children.map(children, child =>
            cloneElement(child, {
              onClick: () => {
                window.zE && zE('webWidget', 'open')
                onClick && onClick()
              },
            })
          )
        ) : (
          <NotificationButton
            onClick={() => {
              window.zE && zE('webWidget', 'open')
              onClick && onClick()
            }}
          >
            <HelpIcon />
          </NotificationButton>
        ))}
    </>
  )
}
