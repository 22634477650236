import { flatten, partition, splitEvery } from 'ramda'
import { MpxParcelsQuery, MpxParcel } from '../types/MpxParcelsTypes'
import { MpxListResponse } from '../types/MpxTypes'

import { getMpAgent } from './utils'

const agent = getMpAgent()

const PARCEL_FETCH_BATCH_SIZE = 40

const PARCEL_CACHE_TTL = 24 * 60 * 60 * 1000 // 1 day

const PATHS = {
  FETCH_DATA: '/mpx/getParcelsByPks',
}

let parcelCache: { [parcelId: string]: { expirationDate: number; parcel: MpxParcel } } = {}

const getCachedParcels = (parcelIds: string[], now: number) => {
  const [cachedParcelIds, uncachedParcelIds] = partition(
    (id: string) => parcelCache[id] && parcelCache[id].expirationDate > now
  )(parcelIds)

  const cachedParcels = cachedParcelIds.map(id => parcelCache[id].parcel)

  return {
    cachedParcels,
    uncachedParcelIds,
  }
}

const cacheParcels = (parcels: MpxParcel[], now: number) => {
  parcels.forEach((parcel: MpxParcel) => {
    parcelCache[parcel.id] = {
      parcel,
      expirationDate: now + PARCEL_CACHE_TTL,
    }
  })
}

export const fetchParcelBatch = async (parcelIdsToFetch: string[]) => {
  const parcelPkList = parcelIdsToFetch.join(',')

  const response = await agent.get<MpxListResponse<any>>(`${PATHS.FETCH_DATA}`, {
    params: { parcelPkList },
  })

  return response.data.items
}

export class MpxParcelsAPI {
  static async fetch(query: MpxParcelsQuery): Promise<MpxParcel[]> {
    const parcelIds = query.parcelPkList.filter((p: string) => p && p.length > 0)

    const requestTime = new Date().valueOf()

    const { cachedParcels, uncachedParcelIds } = getCachedParcels(parcelIds, requestTime)

    if (uncachedParcelIds.length === 0) {
      return cachedParcels
    }

    const parcelIdBatches = splitEvery(PARCEL_FETCH_BATCH_SIZE, uncachedParcelIds)

    const loadedBatches = await Promise.all(parcelIdBatches.map(fetchParcelBatch))

    const loadedParcels = flatten(loadedBatches)

    cacheParcels(loadedParcels, requestTime)

    return [...cachedParcels, ...loadedParcels]
  }

  static clearCache = () => {
    parcelCache = {}
  }
}
