import { JobsRequest, JobsResponse } from '../types'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  JOBS: '/jobs',
}

export class JobsAPI {
  static async jobs(params?: JobsRequest): Promise<JobsResponse> {
    //@ts-ignore
    return agent.get(PATHS.JOBS, { params }).then(response => {
      //@ts-ignore
      return response.data ? (response.data.items as JobsResponse) : undefined
    })
  }
}
