import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { init as initConfig } from './initConfig'

import { QueryClientProvider } from '@tanstack/react-query'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import { Suspense, createRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import 'utils/initFirebase'

import { EnvAuthCheck, IdleTimer, Loading, UserWebSocketProvider } from '@cibo/profile'
import { BuildInfo, CiboUiProvider, CloseButton } from '@cibo/ui'

import ErrorBoundary from 'components/ErrorBoundary'
import CONFIG from 'consts/envdex'
import 'normalize.css'
import { store } from 'store/configureStore'
import { Config } from 'types/Config'
import { homepage } from '../package.json'
import { getQueryClient } from './store/getQueryClient'

import '@cibo/ui/src/theme/reset.scss'
import { CssBaseline } from '@mui/material'
import { ImpactApp } from './ImpactApp'

//@ts-ignore
const isIntegrationTest = window.Cypress || window.localStorage.driver === 'playwright'
declare const __IMPACT_BUILD_INFO__: BuildInfo

initConfig()

if (import.meta.env.PROD || import.meta.env.VITE_DEBUG_SENTRY) {
  Sentry.init({
    dsn: Config.get('SENTRY_DSN'),
    environment: Config.get('SENTRY_ENVIRONMENT'),
    ignoreErrors: ['ResizeObserver', 'Network Error'],
    integrations: [new BrowserTracing()],

    // https://github.com/getsentry/sentry-javascript/issues/1964
    normalizeDepth: 5,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
    release: import.meta.env.VITE_RELEASE_NAME,

    beforeBreadcrumb: (breadcrumb, hint) => {
      switch (breadcrumb.category) {
        case 'ui.click':
          const { tagName, textContent } = hint?.event.target
          breadcrumb.message = `${tagName}: "${textContent}" | ${breadcrumb.message}`
          break
        default:
          break
      }
      return breadcrumb
    },
  })
}

const queryClient = getQueryClient()

const Root = () => {
  const persistor = persistStore(store)
  const [buildInfo] = useState(__IMPACT_BUILD_INFO__)

  const notistackRef = createRef<SnackbarProvider>()
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key)
  }

  return (
    <CiboUiProvider buildInfo={buildInfo}>
      <ErrorBoundary>
        <>{/* @ts-ignore */}</>
        <ReduxProvider store={store}>
          <UserWebSocketProvider baseUrl={Config.get('MP_BASE_URL_WS')} disable={isIntegrationTest}>
            <QueryClientProvider client={queryClient}>
              <EnvAuthCheck>
                <PersistGate persistor={persistor}>
                  <Suspense fallback={<Loading />}>
                    <BrowserRouter basename={`/${homepage}`}>
                      <SnackbarProvider
                        ref={notistackRef}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        action={key => (
                          <CloseButton
                            sx={{ color: 'inherit' }}
                            onClick={onClickDismiss(key)}
                            ariaLabel="close"
                          />
                        )}
                      >
                        <CssBaseline />
                        <ImpactApp />
                        <IdleTimer />
                      </SnackbarProvider>
                    </BrowserRouter>
                  </Suspense>
                </PersistGate>
              </EnvAuthCheck>
            </QueryClientProvider>
          </UserWebSocketProvider>
        </ReduxProvider>
      </ErrorBoundary>
    </CiboUiProvider>
  )
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<Root />)

// expose state+config for Playwright
if (isIntegrationTest) {
  //@ts-ignore
  window.store = store
  //@ts-ignore
  window.config = CONFIG
  //@ts-ignore
  window.queryClient = queryClient
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
