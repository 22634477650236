import md5 from 'md5'

import { AuthAPI } from '@cibo/core'
import { ENV, UserProperties, setUserProperties } from '@cibo/profile'
import { Config } from 'types/Config'

export const reportUserProperties = async (
  reportProps?: Record<string, string | number | null | undefined>
) => {
  const userProperties: UserProperties = {
    environment: Config.get('DEV_ENV') ? ENV.DEV : ENV.PROD,
    ...reportProps,
  }

  if (Config.get('DEV_ENV')) {
    try {
      const authData = await AuthAPI.devAuthCheck()

      //@ts-ignore
      const emailHash = md5(authData.data.email)

      userProperties.devUserId = emailHash
    } catch (e) {
      console.warn('Dev Environment authData not loaded')
    }
  }

  setUserProperties(userProperties)
}
