import { createContext, useCallback, useContext, useState } from 'react'

type SaveIndicatorState = {
  isDirty: boolean
  isError: boolean
  isSaving: boolean
  reset: () => void
  setIsDirty: (isDirty: boolean) => void
  setIsError: (error: boolean) => void
  setIsSaving: (isSaving: boolean) => void
}

const DEFAULT_VALUES = {
  isDirty: false,
  isError: false,
  isSaving: false,
  reset: () => {},
  setIsDirty: () => {},
  setIsError: () => {},
  setIsSaving: () => {},
} as SaveIndicatorState

export const SaveIndicatorContext = createContext<SaveIndicatorState>(DEFAULT_VALUES)

type SaveIndicatorProviderProps = {
  children: React.ReactNode
}

/*
 * Save Indicator Provider is a status provider that can be used to change the status of
 * a save indicator anywhere in the UI contained in the provider. For more on how to use
 * the save indicator, see a comment in `./SaveIndicator`
 *
 * It also allows any component contained within the provider to indicate that there is
 * a detail being saved and when it's successfully been saved by changing the state.
 *
 * Use of this indicator is most prevalent in V2 program tasks and may look like the following:
 * ```
 *   const { setIsDirty, setIsError, setIsSaving } = useSaveIndicator()
 *
 *   const saveDetail = () => {
 *      setIsSaving(true)
 *      setIsDirty(true)
 *      apiCall()
 *        .catch(error => {
 *          setIsError(true)
 *          })
 *        })
 *        .finally(() => setIsSaving(false))
 *  }
 * ```
 */

export const SaveIndicatorProvider = ({ children }: SaveIndicatorProviderProps) => {
  const [isDirty, setIsDirty] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const reset = useCallback(() => {
    setIsDirty(false)
    setIsError(false)
    setIsSaving(false)
  }, [])

  return (
    <SaveIndicatorContext.Provider
      value={{
        isDirty,
        isError,
        isSaving,
        reset,
        setIsDirty,
        setIsError,
        setIsSaving,
      }}
    >
      {children}
    </SaveIndicatorContext.Provider>
  )
}

export const useSaveIndicator = () => useContext(SaveIndicatorContext)
