import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  LinkProps,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
} from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import ReactMarkdown, { MarkdownToJSX } from 'markdown-to-jsx'
import { PropsWithChildren, ReactNode } from 'react'
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom'

export const MarkdownUnorderedListContainer = ({ sx, ...props }: BoxProps) => (
  <Box component="ul" sx={{ pl: 2, listStyle: 'disc', ...sx }} {...props} />
)

export const MarkdownOrderedListContainer = ({ sx, ...props }: BoxProps) => (
  <Box
    component="ol"
    sx={{
      pl: 3,
      mt: 0,
      mb: 3,
      '&:last-of-type': { mb: 0 },
      '& li:not(:last-child)': { mb: 1.25 },
      ...sx,
    }}
    {...props}
  />
)

export const MarkdownListItem = ({ sx, ...props }: BoxProps) => (
  <Box component="li" sx={{ mt: 0, lineHeight: '165%', ...sx }} {...props} />
)

const wrapper = (color?: string) => (props: BoxProps) => {
  return <Box sx={{ mt: 0, color }} {...props} />
}
const typographyWrapper = (typographyProps: TypographyProps) => (props: BoxProps) => {
  return <Typography component="div" sx={{ mt: 0 }} {...typographyProps} {...props} />
}

export const MarkdownLink = ({
  children,
  href,
  replace,
  state,
  title,
  target,
  sx,
}: PropsWithChildren<
  Pick<RRLinkProps, 'state' | 'replace'> & HTMLAnchorElement & Pick<LinkProps, 'sx'>
>) => {
  const externalLink = href?.startsWith('http')
  if (externalLink) {
    return (
      <Link href={href} target={'_blank'} sx={sx}>
        {children as ReactNode}
      </Link>
    )
  }
  if (href?.startsWith('#')) {
    return (
      // Link wants to add the path+hash to the current URL, but we just want to change the hash
      <Link sx={sx} title={title} onClick={() => (window.location.hash = href)}>
        {children as ReactNode}
      </Link>
    )
  }
  return (
    <Link
      component={RRLink}
      replace={replace}
      state={state}
      sx={sx}
      to={href}
      title={title}
      target={target}
    >
      {children as ReactNode}
    </Link>
  )
}

const options = ({
  typographyProps,
  color,
  overrides,
}: {
  typographyProps: TypographyProps
  color?: string
  overrides?: MarkdownToJSX.Options['overrides']
}) =>
  ({
    wrapper: typographyProps ? typographyWrapper(typographyProps) : wrapper(color),
    overrides: {
      h1: {
        component: Typography,
        props: { ...typographyProps, gutterBottom: true, variant: 'h1', component: 'h1', color },
      },
      h2: {
        component: Typography,
        props: { ...typographyProps, gutterBottom: true, variant: 'h2', component: 'h2', color },
      },
      h3: {
        component: Typography,
        props: { ...typographyProps, gutterBottom: true, variant: 'h3', component: 'h3', color },
      },
      h4: {
        component: Typography,
        props: {
          ...typographyProps,
          gutterBottom: true,
          variant: 'h4',
          component: 'h4',
          paragraph: true,
          color,
        },
      },
      h5: {
        component: Typography,
        props: {
          ...typographyProps,
          gutterBottom: true,
          variant: 'h5',
          component: 'h5',
          paragraph: true,
          color,
        },
      },
      h6: {
        component: Typography,
        props: {
          ...typographyProps,
          gutterBottom: true,
          variant: 'h6',
          component: 'h6',
          paragraph: true,
          color,
        },
      },
      p: {
        component: Typography,
        props: {
          ...typographyProps,
          paragraph: true,
          color,
          sx: { mb: 3, '&:last-of-type': { mb: 0 } },
        },
      },
      span: {
        component: Typography,
        props: { ...typographyProps, color },
      },
      a: { component: Link },
      ul: {
        component: MarkdownUnorderedListContainer,
        props: { ...typographyProps, color },
      },
      ol: {
        component: MarkdownOrderedListContainer,
        props: { ...typographyProps, color },
      },
      li: {
        component: MarkdownListItem,
        props: { ...typographyProps, color },
      },
      em: {
        component: ({ children }: { children: ReactNode }) => (
          <span style={{ fontStyle: 'italic' }}>{children}</span>
        ),
      },
      table: {
        component: ({ children }: { children: ReactNode }) => (
          <TableContainer>
            <Table>{children}</Table>
          </TableContainer>
        ),
      },
      thead: {
        component: ({ children }: { children: ReactNode }) => (
          <TableHead
          // sx={{ backgroundColor: 'inherit', color: '#000' }}
          >
            {children}
          </TableHead>
        ),
      },
      tbody: {
        component: ({ children }: { children: ReactNode }) => <TableBody>{children}</TableBody>,
      },
      tr: {
        component: ({ children }: { children: ReactNode }) => <TableRow>{children}</TableRow>,
      },
      th: {
        component: ({ children }: { children: ReactNode }) => (
          <TableCell sx={{ color: '#000' }}>{children}</TableCell>
        ),
      },
      td: {
        component: ({ children }: { children: ReactNode }) => <TableCell>{children}</TableCell>,
      },
      ...overrides,
    },
  } as MarkdownToJSX.Options)

interface MarkdownProps extends MarkdownToJSX.Options {
  color?: string
  typographyProps?: TypographyProps
  sx?: SxProps
}

export const Markdown = ({
  children,
  color,
  typographyProps = {},
  overrides,
  sx = {},
  ...reactMarkdownProps
}: PropsWithChildren<MarkdownProps>) => (
  <ReactMarkdown
    options={options({ typographyProps, color, overrides })}
    {...reactMarkdownProps}
    sx={{ section: { mb: 3 }, ...sx }}
  >
    {typeof children === 'string' && children.length > 0 ? children : ''}
  </ReactMarkdown>
)

export const MarkdownWithAccordion = (props: PropsWithChildren<MarkdownProps>) => {
  return (
    <Markdown
      color="text.secondary"
      {...props}
      overrides={{
        Accordion: {
          component: ({ children, title, subtitle }) => {
            return (
              <Accordion
                elevation={0}
                square
                sx={{
                  backgroundColor: 'unset',
                  marginTop: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Stack>
                    <Typography fontWeight="bold">{title}</Typography>
                    {subtitle && (
                      <Typography variant="subtitle2" color="text.secondary">
                        {subtitle}
                      </Typography>
                    )}
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
              </Accordion>
            )
          },
        },
        pre: {
          component: () => null,
        },
        ...props.overrides,
      }}
    >
      {props.children}
    </Markdown>
  )
}
