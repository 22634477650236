/* istanbul ignore file */
import packageInfo from '../../package.json'
const isLocalhost =
  document &&
  (document.location.hostname === 'localhost' ||
    document.location.hostname.includes('.localdomain') ||
    document.location.hostname.includes('.localhost'))

/**
 * GIT_COMMIT, GIT_BRANCH, and BUILD_NUMBER are Jenkins environment variables
 * https://jenkins.cibo.tech/env-vars.html/
 */
window._versionInfo = {
  version: packageInfo.version,
  // for these to work, they would be copied to VITE_ prefixed env vars
  gitBranch: import.meta.env.GIT_BRANCH,
  gitCommit: import.meta.env.GIT_COMMIT,
  buildNumber: import.meta.env.BUILD_NUMBER,
}

const CONFIG = {
  AUTH0_ENVIRONMENT_DOMAIN: import.meta.env.VITE_AUTH0_ENVIRONMENT_DOMAIN,
  AUTH0_ENVIRONMENT_CLIENT_ID: import.meta.env.VITE_AUTH0_ENVIRONMENT_NATIVE_CLIENT_ID,
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_NATIVE_CLIENT_ID,

  DEV_ENV: Number(import.meta.env.VITE_DEV_ENV),
  DEV_PERMISSIONS: (import.meta.env.VITE_DEV_PERMISSIONS || '').split(','),

  FOOTPRINT_URL: import.meta.env.VITE_FOOTPRINT_URL,
  MAP_THUMBNAIL_URL: import.meta.env.VITE_MAP_THUMBNAIL_URL,
  NODE_SERVICE_URL: import.meta.env.VITE_NODE_SERVICE_URL,

  MP_BASE_URL: isLocalhost ? '/' : import.meta.env.VITE_MP_BASE_URL_WEB,
  MP_BASE_URL_WS: isLocalhost ? 'ws://localhost:3003/' : import.meta.env.VITE_MP_BASE_URL_WS,
  MP_BASE_URL_FULL: import.meta.env.VITE_MP_BASE_URL_WEB,
  MAPBOX_ACCESS_TOKEN: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
  MAPBOX_STYLE_SATELLITE_LIGHT: import.meta.env.VITE_MAPBOX_STYLE_SATELLITE_LIGHT,
  MAPBOX_STYLE_FOCUSED_LIGHT: import.meta.env.VITE_MAPBOX_STYLE_FOCUSED_LIGHT,
  MAPBOX_STYLE_CDL_LIGHT: import.meta.env.VITE_MAPBOX_STYLE_CDL_LIGHT,
  MAPBOX_STYLE_SATELLITE_DARK: import.meta.env.VITE_MAPBOX_STYLE_SATELLITE_DARK,
  MAPBOX_STYLE_FOCUSED_DARK: import.meta.env.VITE_MAPBOX_STYLE_FOCUSED_DARK,
  MAPBOX_STYLE_CDL_DARK: import.meta.env.VITE_MAPBOX_STYLE_CDL_DARK,

  ENVIRONMENT_CHECK: import.meta.env.VITE_ENVIRONMENT_CHECK,
  CIBO_EXTERNAL_URL: import.meta.env.VITE_CIBO_EXTERNAL_URL,
  MAPTILES_SERVICE_URL: import.meta.env.VITE_MAPTILES_SERVICE_URL,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  CONTENTFUL_SPACE_ID: import.meta.env.VITE_CONTENTFUL_SPACE_ID,
  CONTENTFUL_DELIVERY_TOKEN: import.meta.env.VITE_CONTENTFUL_DELIVERY_TOKEN,
  CONTENTFUL_PREVIEW_TOKEN: import.meta.env.VITE_CONTENTFUL_PREVIEW_TOKEN,
  FIELD_EDITOR_HELP_VIDEO_ID: import.meta.env.VITE_FIELD_EDITOR_HELP_VIDEO_ID,

  FIREBASE_APP_DOMAIN: import.meta.env.VITE_FIREBASE_APP_DOMAIN || '',

  BUY_CREDITS_LANDING_PAGE: import.meta.env.VITE_BUY_CREDITS_LANDING_PAGE,
  ENROLL_LAND_LANDING_PAGE: import.meta.env.VITE_ENROLL_LAND_LANDING_PAGE,

  BUILD_NUMBER: import.meta.env.BUILD_NUMBER,
  GIT_BRANCH: import.meta.env.GIT_BRANCH,
  GIT_COMMIT: import.meta.env.GIT_COMMIT,
  VERSION: packageInfo.version,
}

export default CONFIG
