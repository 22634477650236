import { ThumbnailLayer } from './types'

const pixelRatio = window.devicePixelRatio

export const pathSegmentForLayer = (layer: ThumbnailLayer) => {
  const pathParts = []

  if (layer.lineWidth) {
    pathParts.push('lineWidth')
    pathParts.push((layer.lineWidth * pixelRatio).toFixed(1))
  }
  if (layer.lineColor) {
    pathParts.push('lineColor')
    pathParts.push(layer.lineColor.replace('#', ''))
  }
  if (layer.fillColor) {
    pathParts.push('fillColor')
    pathParts.push(layer.fillColor.replace('#', ''))
  }

  pathParts.push(layer.geometrySlug)

  return pathParts.join('/')
}
