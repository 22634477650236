import chroma from 'chroma-js'
import { Theme } from '../../consts'

import { LandThumbnailSkin, LandThumbnailProfile, ThumbnailLayer } from './types'

const outlineTillableSkin = (
  landProfile: LandThumbnailProfile,
  color: string
): ThumbnailLayer[] => {
  const fillColor = color ? chroma(color).alpha(0.5).hex() : Theme.color.map.mine.fill
  return [
    {
      fillColor,
      geometrySlug: landProfile?.tillableGeometrySlug,
    },
    {
      lineColor: color,
      lineWidth: 3,
      geometrySlug: landProfile?.geometrySlug,
    },
  ]
}

export const layersForProfileSkin = (
  landProfile: LandThumbnailProfile,
  skin: LandThumbnailSkin
): ThumbnailLayer[] => {
  const mapColor = Theme.color.map

  switch (skin) {
    case LandThumbnailSkin.BLANK:
      return [
        {
          geometrySlug: landProfile.geometrySlug,
        },
      ]

    case LandThumbnailSkin.SELECTED:
      return outlineTillableSkin(landProfile, '#f9ee56')

    case LandThumbnailSkin.USER_FEATURE:
      return outlineTillableSkin(landProfile, Theme.color.map.mine.outline)

    case LandThumbnailSkin.SMALL:
      return [
        {
          geometrySlug: landProfile.geometrySlug,
          lineColor: '#f9ee56',
          lineWidth: 2,
        },
      ]

    case LandThumbnailSkin.CARBON_CREDIT_POPUP_AVAILABLE:
      return [
        {
          geometrySlug: landProfile.geometrySlug,
          lineColor: mapColor.carbon.outline,
          fillColor: mapColor.carbon.satelliteFill,
          lineWidth: 2,
        },
      ]
    case LandThumbnailSkin.CARBON_CREDIT_POPUP_PENDING:
      return [
        {
          geometrySlug: landProfile.geometrySlug,
          lineColor: mapColor.carbonPending.outline,
          fillColor: mapColor.carbonPending.satelliteFill,
          lineWidth: 2,
        },
      ]
    case LandThumbnailSkin.CARBON_CREDIT_POPUP_UNAVAILABLE:
      return [
        {
          geometrySlug: landProfile.geometrySlug,
          lineColor: mapColor.carbonUnavailable.outline,
          fillColor: mapColor.carbonUnavailable.satelliteFill,
          lineWidth: 2,
        },
      ]

    default:
      return [
        {
          fillColor: chroma(Theme.color.surface.main.primary).alpha(0.5).hex(),
          geometrySlug: landProfile.tillableGeometrySlug,
        },
        {
          lineColor: Theme.color.secondary,
          lineWidth: 3,
          geometrySlug: landProfile.geometrySlug,
        },
      ]
  }
}
