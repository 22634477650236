import { FarmSearchQuery } from '../types/FarmsTypes'
import { MpxResponseBaseSingle } from '../types/MpxTypes'
import { PaginationRequest, PaginationResponse } from '../types/PaginationTypes'
import {
  AssignFieldsRequest,
  CreateTermsetRequest,
  Termset,
  UpdateTermsetRequest,
} from '../types/Termset'
import { generatePath } from '../utils'
import { getMpAgent } from './utils'

const mpAgent = getMpAgent()

type Paths = {
  LIST: string
  ENTITY: string
  FIELDS: string
  QUERY: string
}

export interface TermsetAPIType {
  PATHS: Paths
}

export class TermsetAPI<T extends Termset> implements TermsetAPIType {
  PATHS: Paths
  constructor(params: Paths) {
    this.PATHS = params
  }
  create(request: CreateTermsetRequest<T>) {
    return mpAgent
      .post<MpxResponseBaseSingle<T>>(this.PATHS.LIST, request)
      .then(response => response?.data)
  }

  search(query?: PaginationRequest<FarmSearchQuery>) {
    return mpAgent
      .get<PaginationResponse<T>>(this.PATHS.QUERY, { params: query })
      .then(response => response?.data)
  }

  read(id: string) {
    return mpAgent
      .get<MpxResponseBaseSingle<T>>(generatePath(this.PATHS.ENTITY, { id }))
      .then(response => response?.data)
  }

  update(request: UpdateTermsetRequest<T>) {
    return mpAgent
      .put<MpxResponseBaseSingle<T>>(generatePath(this.PATHS.ENTITY, { id: request.id }), {
        ...request,
        id: undefined,
      })
      .then(response => response?.data)
  }

  assignFields({ termsetId, fieldIds }: AssignFieldsRequest) {
    return mpAgent
      .post(this.PATHS.FIELDS, {
        id: termsetId,
        resourceIds: fieldIds.join(','),
        doAutoUnlink: true,
      })
      .then(response => response?.data)
  }
  unassignFields({ termsetId, fieldIds }: AssignFieldsRequest) {
    return mpAgent
      .delete(this.PATHS.FIELDS, { params: { id: termsetId, resourceIds: fieldIds.join(',') } })
      .then(response => response?.data)
  }

  delete(id: string) {
    return mpAgent.delete(generatePath(this.PATHS.ENTITY, { id })).then(response => response?.data)
  }
}
