import CancelIcon from '@mui/icons-material/Cancel'
import { Button, Divider, Stack } from '@mui/material'
import { DataGridProProps, GridPagination, useGridApiContext } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { ExtendedDataGridProProps } from './types'

export const ServerPaginationFooter = ({
  hideFooterPagination,
  hideFooterSelectedRowCount,
}: DataGridProProps & ExtendedDataGridProProps) => {
  const { t } = useTranslation('@cibo/ui/ServerPaginationFooter')
  const { current: grid } = useGridApiContext()

  return (
    <>
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" p={1} alignItems="center" spacing={2}>
          {!hideFooterSelectedRowCount && grid.getSelectedRows().size > 0 && (
            <>
              <Button onClick={() => grid.setRowSelectionModel([])} startIcon={<CancelIcon />}>
                {t('clearSelection', { count: grid.getSelectedRows().size })}
              </Button>
            </>
          )}
        </Stack>

        {!hideFooterPagination && <GridPagination {...({} as any)} />}
      </Stack>
    </>
  )
}
