import { RecommendationPointsResponse } from '../types/RecommendationPointsTypes'
import { generatePath } from '../utils'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  SUMMARY: '/program-engagement-eqip-acceptance-score/:engagementId',
}

export type RecommendationsDataType = {
  engagementId: string
  doFullLoad?: boolean
  selectedPractices?: string[]
}

export class RecommendationsAndAcceptanceScoreForPoolsInputAPI {
  static summary({ engagementId, ...data }: RecommendationsDataType) {
    return agent
      .post<RecommendationPointsResponse>(generatePath(PATHS.SUMMARY, { engagementId }), data)
      .then(response => response.data)
  }
}
