import { Box, TextField, styled } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useFormikContext } from 'formik'

const GroupedInputs = styled(Box)(
  ({
    theme: {
      shape: { borderRadius },
    },
  }) => ({
    display: 'flex',
    flexDirection: 'row',

    fieldset: {
      borderRadius: 0,
      marginLeft: -1,
    },

    '> *:first-of-type fieldset': {
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
      marginLeft: 0,
    },
    '> *:last-child fieldset': {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  })
)

interface DatePickerFieldProps extends Partial<DateRangePickerProps<any, any>> {
  name: string
  startRangeFieldName: string
  endRangeFieldName: string
}

export const DateRangePickerField = ({
  name,
  startRangeFieldName,
  endRangeFieldName,
  ...dateRangePickerProps
}: DatePickerFieldProps) => {
  const { getFieldMeta, setFieldValue } = useFormikContext()
  const parentField = getFieldMeta<{ startDate: Date | null; endDate: Date | null }>(name)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        onChange={range => {
          const [startValue, endValue] = range
          setFieldValue(startRangeFieldName, startValue)
          setFieldValue(endRangeFieldName, endValue)
        }}
        value={[parentField.value.startDate, parentField.value.endDate]}
        renderInput={({ label: startLabel, ...startProps }, { label: endLabel, ...endProps }) => (
          <GroupedInputs>
            <TextField
              {...startProps}
              inputProps={{
                ...startProps.inputProps,
                'data-testid': startRangeFieldName,
              }}
            />
            <TextField
              {...endProps}
              inputProps={{
                ...endProps.inputProps,
                'data-testid': endRangeFieldName,
              }}
            />
          </GroupedInputs>
        )}
        {...dateRangePickerProps}
      />
    </LocalizationProvider>
  )
}
