import { Persona } from '../types'
import { getMpNodeAgent } from './utils'

const TIMEOUT = 1000 * 60 * 3
const agent = getMpNodeAgent()

interface EnrolledReportQuery {
  commitStartDate?: Date | null
  commitEndDate?: Date | null
}
export interface EnrolledWorkbooksQuery extends EnrolledReportQuery {
  persona?: Persona | Persona[]
}

export interface EnrolledShapefilesQuery extends EnrolledReportQuery {
  persona?: Persona
}

const PATHS = {
  ENROLLED_SHAPEFILES: '/report/v1/enrolled/shapefiles',
  ENROLLED_WORKBOOKS: '/report/v1/enrolled/workbooks',
}

export class ReportAPI {
  static enrolled = {
    shapefiles: (params: EnrolledShapefilesQuery): Promise<any> => {
      return agent.get(PATHS.ENROLLED_SHAPEFILES, {
        params,
        responseType: 'blob',
        timeout: TIMEOUT,
      })
    },

    workbooks: (params: EnrolledWorkbooksQuery): Promise<any> => {
      return agent.get(PATHS.ENROLLED_WORKBOOKS, { params, responseType: 'blob', timeout: TIMEOUT })
    },
  }
}
