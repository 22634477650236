import { FieldHistoryResult } from '../types'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  FETCH_DATA: '/insights/getFieldHistory',
}

interface FieldHistoryQuery {
  resourceId: string
}

export class FieldHistoryAPI {
  static fetch(query: FieldHistoryQuery): Promise<FieldHistoryResult> {
    return agent
      .get(`${PATHS.FETCH_DATA}`, { params: { ...query }, timeout: 60 * 1000 })
      .then(response => response?.data as FieldHistoryResult)
  }
}
