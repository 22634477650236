export const EVENTS = {
  HTTP_REQUEST_ERROR: {
    name: 'http_request_error',
    params: ['method', 'url', 'status'],
  },
  PAGE_VIEW: {
    name: 'page_view',
    params: ['path', 'userId'],
  },
  SCREEN_VIEW: {
    name: 'screen_view',
    params: ['screen'],
  },
  SEARCH: {
    name: 'search',
    params: ['type', 'term', 'trigger'],
  },
  TAP_FILTER_BUTTON: {
    name: 'tap_filter_button',
    params: ['filterButton'],
  },
  RESET_FILTERS: {
    name: 'reset_filters',
    params: ['filters'],
  },
  ADVANCED_SEARCH: {
    name: 'advanced_search',
  },
}
